<template>
   <div class="-games-index-page">
    <div class="js-replace-heading-sub-title-seo-container">
     <h1 class="-sub-title">Casino ทุกค่ายชั้นนำที่ดีที่สุดมาให้บริการ</h1>
    </div>
    <div class="x-category-provider js-game-scroll-container js-game-container">
      <div class="x-category-provider js-game-scroll-container js-game-container">
        <div class="-games-list-container container">
          <nav class="nav-menu" id="navbarProvider">
            <ul class="nav nav-pills">
              <li class="nav-item -game-casino-macro-container" v-for="(item,index) in getGames.games"  v-bind:key="index">
                <div class="x-game-list-item-macro js-game-list-toggle -big " data-status="">
                  <div class="-inner-wrapper">
                    <picture>
                      <a href="#" class="-btn -btn-play js-account-approve-aware" @click="reqStartGame(item.id, require('@/assets/images/casino/banner/'+item.games_provider_id+'.png'), item.games_provider_id)" v-if="isMember === true">
                        <img :src="require('@/assets/images/casino/banner/'+item.games_provider_id+'.png')" class="-cover-img img-fluid ls-is-cached lazyloaded" alt="wt-pretty-gaming" width="364" height="231">
                      </a>
                      <a href="#loginModal" v-b-modal.modal-user-login class="-btn -btn-play js-account-approve-aware"  v-else>
                        <img :src="require('@/assets/images/casino/banner/'+item.games_provider_id+'.png')" class="-cover-img img-fluid ls-is-cached lazyloaded" alt="wt-pretty-gaming" width="364" height="231">
                      </a>
                    </picture>
                    <div class="-overlay">
                      <div class="-overlay-inner">
                        <div class="-wrapper-container">
                          <a href="#loginModal" class="-btn -btn-play js-account-approve-aware" @click="reqStartGame(item.id, require('@/assets/images/casino/banner/'+item.games_provider_id+'.png'), item.games_provider_id)" v-if="isMember === true">
                            <i class="fas fa-play"></i>
                            <span class="-text-btn">เข้าเล่น</span>
                          </a>
                          <a href="#loginModal" v-b-modal.modal-user-login class="-btn -btn-play js-account-approve-aware" v-else>
                            <i class="fas fa-play"></i>
                            <span class="-text-btn">เข้าเล่น</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                <div class="-title card-section ">{{ item.games_provider_id }}</div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
</template>

// <b-row>
//           <b-col
//           v-for="(item,index) in getGames.games"
//           v-bind:key="index"
//           md=12
//           >
//             <div class="mt-3 px-2 box-new-top img-cuesor-pointer" @click="reqStartGame(item.id, require('@/assets/images/casino/banner/'+item.games_provider_id+'.png'), item.games_provider_id)" sty>
//               <div class="card">
//                 <img class="card-img lightSweep" :src="require('@/assets/images/casino/banner/'+item.games_provider_id+'.png')" alt="Card image">
//               </div>
//             </div>
//           </b-col>
//         </b-row>

<script>
import { ApiService } from '@/utils'
import { mapState, mapActions, mapGetters } from 'vuex'
const client = new ApiService({})

export default {
  async mounted () {
    if (process.env.VUE_APP_CASINO_ENABLE.toLowerCase().trim() === 'false') {
      this.$router.push('/member')
    }
    try {
      await this.getGamesList({
        type: 2
      })
    } catch (error) {
    }
  },
  computed: {
    ...mapGetters([
      'getGames',
      'getGameUrl'
    ]),
    ...mapState({
      isMember: (state) => state.authModule.isMember
    })
  },
  methods: {
    ...mapActions(['getGamesList', 'getGamesUrl', 'setGameDepositCreditAll', 'setLoading']),
    async reqStartGame (gameId, game_img, game_name) {
      this.setLoading(true)
      const resp = await client.post('/game/request/start', JSON.stringify({
        id: gameId
      }))
      this.setLoading(false)
      if (resp.status_code === 'SUCCESS') {
        this.link_to_game = process.env.VUE_APP_CASINO_URL + '/' + localStorage.getItem('client_id') + '/' + resp.data._token
        this.$swal({
          text: 'คุณต้องการเข้าเล่น ' + game_name + ' ?',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'ใช่',
          cancelButtonText: 'ปิด',
          imageUrl: game_img,
          imageWidth: '100%'
        }).then((result) => {
          if (result.isConfirmed) {
            window.open(process.env.VUE_APP_CASINO_URL + '/' + localStorage.getItem('client_id') + '/' + resp.data._token)
          }
        })
      }
    }
  }
}
</script>

<style>
  .-sub-title {
    margin-top: 2rem;
       margin-bottom: 1rem;
    padding: 0 1rem;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.5;
    color: #fff;
    text-align: center;
  }
</style>
