<template>
   <div class="-games-index-page">
    <div class="js-replace-heading-sub-title-seo-container">
     <h1 class="-sub-title">โปรโมชั่น</h1>
    </div>
    <div class="x-category-provider js-game-scroll-container js-game-container">
      <div class="x-category-provider js-game-scroll-container js-game-container">
        <div class="-games-list-container container">
            <div class="container">
              <div class="row m-0 p-2" v-if="Array.isArray(getAgentPromotions)">
                <div class="col-sm-12 col-md-6 col-xl-4 p-1" v-for="(item, index) in getAgentPromotions"  @click="setIndex(index)" :key="index">
                  <div class="row m-0 shadow-sm p-1 bg-white bg-radius cursor_pointer" v-b-modal="'modal-promotions'">
                    <div class="col-12 col-sm-12 p-0 ">
                      <img :src="item.picture"  class="w-100 bg-radius">
                      <h2 class="m-0 p-2">{{ item.name }}</h2>
                      <p class="m-0 px-2 detail_pro">{{ item.note }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
    </div>
  </div>
  <b-modal style="height: 100%;" id="modal-promotions" scrollable hide-footer size="xl" v-if="Array.isArray(getAgentPromotions)">
    <img  :src="getAgentPromotions[index].picture"  class="w-100 bg-radius">
                      <h1 class="m-0 p-2 text-white">{{ getAgentPromotions[index].name }}</h1>
                      <p class="m-0 px-2  text-white">{{ getAgentPromotions[index].note }}</p>
    <b-button class="mt-3" variant="primary" size="sm" block @click="$bvModal.hide('modal-promotions')">ปิด</b-button>
  </b-modal>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  async mounted () {
    try {
      // localStorage.setItem('game_permission', 0)
      // await this.setLoading(true)
      // await this.getNewsList()
      await this.getPromotionsList()
      // Background loading data
      // console.log(this.getAgentNews.banner === undefined || this.getAgentNews.banner === null)
      // console.log(typeof this.getAgentNews.banner === 'object')
      // await this.setLoading(false)
    } catch (error) {
      // this.setLoading(false)
    }
  },
  data () {
    return {
      promotions: [
        // { name: 'คืนยอดเสีย 5%', img: '@/assets/temp/money.webp', detail: '- คืนขั้นต่ำ 50 (ยอดเสียรวม 1,000) - โปรนี้รับได้ทุกวันจันทร์ เวลา 08.00 น - 00.00 น- นับผลรวมตั้งแต่ จันทร์ ถึง อาทิตย์- ทำ เทิร์น/โอเวอร์ 1 เท่า' },
        // { name: 'คืนยอดเสีย 5%', img: '@/assets/temp/money.webp', detail: '- คืนขั้นต่ำ 50 (ยอดเสียรวม 1,000) - โปรนี้รับได้ทุกวันจันทร์ เวลา 08.00 น - 00.00 น- นับผลรวมตั้งแต่ จันทร์ ถึง อาทิตย์- ทำ เทิร์น/โอเวอร์ 1 เท่า' },
        // { name: 'คืนยอดเสีย 5%', img: '@/assets/temp/money.webp', detail: '- คืนขั้นต่ำ 50 (ยอดเสียรวม 1,000) - โปรนี้รับได้ทุกวันจันทร์ เวลา 08.00 น - 00.00 น- นับผลรวมตั้งแต่ จันทร์ ถึง อาทิตย์- ทำ เทิร์น/โอเวอร์ 1 เท่า' }
      ],
      index: 0
    }
  },
  methods: {
    setIndex (x) {
      this.index = x
    }
  },
  computed: {
    ...mapActions(['getPromotionsList', 'getNewsList', 'setLoading']),
    ...mapGetters(['getAgentPromotions'])
  }
}
// import mapGetters from 'vuex'
// export default {
//   async mounted () {
//     try {
//       // localStorage.setItem('game_permission', 0)
//       // await this.setLoading(true)
//       // await this.getPromotionsList()
//       // Background loading data
//       // console.log(this.getAgentNews.banner === undefined || this.getAgentNews.banner === null)
//       // console.log(typeof this.getAgentNews.banner === 'object')
//       // await this.setLoading(false)
//     } catch (error) {
//       // this.setLoading(false)
//     }
//   },
//   data () {
//     return {
//       promotions: [
//         { name: 'คืนยอดเสีย 5%', img: '@/assets/temp/money.webp', detail: '- คืนขั้นต่ำ 50 (ยอดเสียรวม 1,000) - โปรนี้รับได้ทุกวันจันทร์ เวลา 08.00 น - 00.00 น- นับผลรวมตั้งแต่ จันทร์ ถึง อาทิตย์- ทำ เทิร์น/โอเวอร์ 1 เท่า' },
//         { name: 'คืนยอดเสีย 5%', img: '@/assets/temp/money.webp', detail: '- คืนขั้นต่ำ 50 (ยอดเสียรวม 1,000) - โปรนี้รับได้ทุกวันจันทร์ เวลา 08.00 น - 00.00 น- นับผลรวมตั้งแต่ จันทร์ ถึง อาทิตย์- ทำ เทิร์น/โอเวอร์ 1 เท่า' },
//         { name: 'คืนยอดเสีย 5%', img: '@/assets/temp/money.webp', detail: '- คืนขั้นต่ำ 50 (ยอดเสียรวม 1,000) - โปรนี้รับได้ทุกวันจันทร์ เวลา 08.00 น - 00.00 น- นับผลรวมตั้งแต่ จันทร์ ถึง อาทิตย์- ทำ เทิร์น/โอเวอร์ 1 เท่า' }
//       ],
//       index: 0
//     }
//   },
//   methods: {
//     setIndex (x) {
//       this.index = x
//     }
//   },
//   computed: {
//     ...mapGetters([
//       'getAgentPromotions'
//     ])
//   }
// }
</script>

<style>
.detail_pro{
    height: 7vh;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
  .-sub-title {
    margin-top: 2rem;
       margin-bottom: 1rem;
    padding: 0 1rem;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.5;
    color: #fff;
    text-align: center;
  }
</style>
