<template>
   <div class="js-tab-menu-provider px-0">
    <div class=" ">
      <div class="py-1 row px-0 m-0 x-menu-provider ">
        <div class="col-3 p-1">
          <router-link to="/hotgame">
            <button class="nav-link py-1 w-100 " :class="{ 'active': isPageActive('hotgame') }">
              <img src="@/assets/menu_center/hotgame.png" alt="Icon nav menu casino" class="img-fluid -ic-menu" width="40" height="40">
              <div class="-text-provider-wrapper">
                <div class="-text-nav-menu -mobile">ยอดนิยม</div>
              </div>
            </button>
          </router-link>
        </div>
        <div class="col-3 p-1">
          <router-link to="/casino">
            <button class="nav-link py-1 w-100" :class="{ 'active': isPageActive('casino') }">
          <img src="@/assets/menu_center/casino.png" alt="Icon nav menu casino" class="img-fluid -ic-menu" width="55" height="55">
          <div class="-text-provider-wrapper">
            <div class="-text-nav-menu -mobile">คาสิโน</div>
              </div>
            </button>
          </router-link>
        </div>
        <div class="col-3 p-1">
          <router-link to="/slot">
            <button class="nav-link py-1 w-100" :class="{ 'active': isPageActive('slot') }">
          <img src="@/assets/menu_center/slot.webp" alt="Icon nav menu casino" class="img-fluid -ic-menu" width="55" height="55">
          <div class="-text-provider-wrapper">
            <div class="-text-nav-menu -mobile">สล็อต</div>
              </div>
            </button>
          </router-link>
        </div>
        <div class="col-3 p-1">
          <router-link to="/lotto">
                <button class="nav-link py-1 w-100" :class="{ 'active': isPageActive('lotto') }">
            <img src="@/assets/menu_center/lotto.png" alt="Icon nav menu casino" class="img-fluid -ic-menu" width="55" height="55">
            <div class="-text-provider-wrapper">
              <div class="-text-nav-menu -mobile">หวย</div>
                </div>
              </button>
            </router-link>
        </div>
        <div class="col-3 p-1">
            <router-link to="/sport">
                <button class="nav-link py-1 w-100" :class="{ 'active': isPageActive('sport') }">
              <img src="@/assets/menu_center/sport.webp" alt="Icon nav menu casino" class="img-fluid -ic-menu" width="55" height="55">
              <div class="-text-provider-wrapper">
                <div class="-text-nav-menu -mobile">กีฬา</div>
                  </div>
                </button>
              </router-link>
        </div>
        <div class="col-3 p-1">
          <router-link to="/visual_sport">
            <button class="nav-link py-1 w-100" :class="{ 'active': isPageActive('visual_sport') }">
                <img src="@/assets/menu_center/virtual.webp" alt="Icon nav menu casino" class="img-fluid -ic-menu" width="55" height="55">
                <div class="-text-provider-wrapper">
                  <div class="-text-nav-menu -mobile">กีฬาจำลอง</div>
                    </div>
                  </button>
                </router-link>
        </div>
        <div class="col-3  p-1">
          <router-link to="/fishing">
            <button class="nav-link p-1 w-100" :class="{ 'active': isPageActive('fishing') }">
                <img src="@/assets/menu_center/Fish.webp" alt="Icon nav menu casino" class="img-fluid -ic-menu" width="55" height="55">
                <div class="-text-provider-wrapper">
                  <div class="-text-nav-menu -mobile">ยิงปลา</div>
                    </div>
                  </button>
                </router-link>
        </div>
        <div class="col-3 p-1">
          <router-link to="/promotions">
            <button class="nav-link py-1 w-100" :class="{ 'active': isPageActive('promotions') }">
                <img src="@/assets/menu_top/menu-promo.png" alt="Icon nav menu casino" class="img-fluid -ic-menu" width="55" height="55">
                <div class="-text-provider-wrapper">
                  <div class="-text-nav-menu -mobile">โปรโมชั่น</div>
                    </div>
                  </button>
                </router-link>
        </div>
      </div>
    </div>
    <div class="container m-0" v-if="getIsMember && isPageActive('lotto')">
      <div class="row m-0 p-0">
        <div class="col-4 p-1">
          <router-link to="/member/numbersets" class="btn btn btn-dark w-100 p-2"><img src="@/assets/icon/file.png" class="-icon icon_mb_menu" alt="Menu icon promotion">เลขชุด</router-link>
        </div>
        <div class="col-4 p-1">
          <router-link to="/member/poy" class="btn btn btn-dark w-100 p-2"><img src="@/assets/icon/ticket.png" class="-icon icon_mb_menu" alt="Menu icon promotion">รายการโพย</router-link>
        </div>
        <div class="col-4 p-1">
          <router-link to="/member/result/all" class="btn btn btn-dark w-100 p-2"><img src="@/assets/icon/lottery.png" class="-icon icon_mb_menu" alt="Menu icon promotion">ผลรางวัล</router-link>
        </div>
      </div>
    </div>
      </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
export default {
  mounted () {
  },
  methods: {
    ...mapActions(['getBalanceInfo', 'setLoading']),
    ...mapState({
      isMember: (state) => state.authModule.isMember
    }),
    isPageActive (pageName) {
      return (this.$route.name === pageName)
    }
  },
  computed: {
    ...mapGetters(['getIsVip', 'getIsMember'])
  }
}
</script>

<style>
</style>
