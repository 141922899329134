<template>
  <div>
    <div id="section-content" class="container container-fluid">
      <div
        class="p-0 w-100 bg-light2 main-content align-self-stretch"
      >
        <div
          class="
            bgwhitealpha
            text-secondary
            rounded
            p-0
            xtarget
            col-lotto
          "
        >
          <!-- <h3 class="title-huay p-2 m-0">หวยชุด</h3> -->
          <div class="row m-0 p-1">
            <div  v-bind:class="list.length == 1 ? 'col-12 p-0' : 'col-6'" v-for="(item, index) in list" :key="index">
              <router-link
                :to="`/member/lottery/lottoset/${item.LottoCatagory.lottoId}`"
                style="padding: 0"
                class="btn btn-outline-primary btn-block btn-contactbox bg-light shadow-sm"
              >
                <span class="lotto-head lotto-yeekee p-0 text-center">{{ item.titleLottoCatagory }}</span>
                <h3 class="m-0 p-2" v-bind:class="getIconClass(item.titleLottoCatagory)"></h3>
              </router-link>
            </div>
            <!-- <div class="col-6" >
              <a
                style="padding: 0"
                class="btn btn-outline-primary bg-gray btn-block btn-contactbox  shadow-sm"
                disabled="">
                <span class="text-white lotto-head lotto-yeekee p-0 text-center">หวยชุดลาว</span>
                <h3 class="m-0 p-1" v-bind:class="getIconClass('หวยชุดลาว')"></h3>
              </a>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAvailableLottoSet } from '@/services'
import { mapState, mapActions } from 'vuex'
import { getClassIcons } from '@/utils'
export default {
  async created () {
    try {
      this.setLoading(true)
      const resp = await getAvailableLottoSet()
      if (resp.status_code === 'SUCCESS') {
        this.list = resp.data.listAvaliableLotto[0].list
      }
      this.setLoading(false)
    } catch (error) {
      this.setLoading(false)
    }
  },
  methods: {
    ...mapActions(['setLoading']),
    ...mapState({
      isMember: (state) => state.authModule.isMember
    }),
    getIconClass (title) {
      if (title) {
        return getClassIcons(title)
      } else {
        return ''
      }
    }
  },
  data () {
    return {
      list: []
    }
  }
}
</script>
<style>
.bg-gray{
  background-color: #898989;
}
</style>
