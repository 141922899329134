<template>
  <div class="lottery">
    <div id="section-content" class="container container-fluid">
      <b-overlay id="overlay-background" :show="getLotteryList.length == 0" rounded="lg"
          variant="dark"
          opacity="0.35"
          blur="2px">
      <div class="p-0 w-100 bg-light2 main-content align-self-stretch" v-for="(lotto, index) in ((getLotteryList.length == 0) ? dummyList.getLotteryList : getLotteryList)" :key="index">
        <div class="bgwhitealpha text-secondary rounded px-0 xtarget col-lotto">
          <h3 class="title-huay p-2 m-0">{{lotto.groupName}}</h3>
          <div class="row m-0" v-if="lotto.list.length > 0">
          <template  v-for="(item, indexSub) in (lotto.list.filter(v => v.isOpen === '1').sort(function (a, b) {return  isATimeOver(a.lottoCloseTime, b.lottoCloseTime) ? -1 : 1 })) ">
            <div class="col-6 col-sm-6 col-md-6 col-lg-3" v-bind:class="item.lottoType==1 ? 'order-1' : 'order-'+(indexSub+lotto.list.length)" :key="item.id" >
              <router-link v-bind:to="item.isOpen !== '1' ? `/member/lottery/lotto/result/${item.lottoId}` : `/member/lottery/lotto/${item.lottoId}`">
                <div class="lotto-card shadow-sm" v-bind:class="item.isOpen !== '1' ? 'lotto-close' : '' ">
                  <div class="ribbon-lotto">
                    <span v-bind:class="getIconClass(item.titleLottoCatagory)"></span>
                    </div>
                  <div class="lotto-head" v-bind:class="getBackgroundColor(item.titleLottoCatagory)">
                    <h5>{{ item.titleLottoCatagory }}</h5>
                    <span class="badge badge-dark">ปิดรับ {{ getTime(item.lottoCloseTime) }}</span>
                  </div>
                  <div>
                    <div class="lotto-time text-success" v-if="item.isOpen !== '1'">
                      <i class="fas fa-ban"></i><span>ยังไม่รับแทง</span>
                    </div>
                    <div v-else class="lotto-time text-success">
                        <i class="fas fa-ban" v-if="item.isOpen !== '1'"></i>
                         <i class="far fa-check-circle"  v-else></i><span
                        class="countdown"
                        id="offset"
                        data-finaldate="1610871600000"
                        v-html="calTime(item.lottoCloseTime)">
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            </template>
            <template v-if="index==0">
            <div class="col-6 col-sm-6 col-md-6 col-lg-3 order-2"  v-for="(itemX, indexX) in dummyList.getYeekiCatalogList" :key="indexX">
              <router-link :to="`/member/lottery/yeeki/${itemX.subCatID}`">
                <div class="lotto-card">
                  <div class="ribbon-lotto"><i class="fas fa-trophy"></i></div>
                  <div class="lotto-head" v-bind:class="itemX.styleName" >
                    <h5>{{itemX.subCatName}}</h5>
                    <span class="badge" v-bind:class="itemX.styleBadgeName">{{itemX.subCatDetail}}</span>
                  </div>
                  <div class="lotto-time">
                    <div class="txt-24">
                      <i class="far fa-check-circle"></i>
                      <span id="offset">24 ชม.</span>
                    </div>
                  </div>
                </div>

              </router-link>
            </div>
          </template>
            <template  v-for="(item, indexSub) in lotto.list.filter(v => v.isOpen !== '1')">
              <div class="col-6 col-sm-6 col-md-6 col-lg-3" v-bind:class="item.lottoType==1 ? 'order-1' : 'order-'+(indexSub+lotto.list.length)" :key="item.id">
                <router-link v-bind:to="item.isOpen !== '1' ? `/member/lottery/lotto/result/${item.lottoId}` : `/member/lottery/lotto/${item.lottoId}`">
                  <div class="lotto-card" v-bind:class="item.isOpen !== '1' ? 'lotto-close' : '' ">
                    <div class="ribbon-lotto">
                      <span v-bind:class="getIconClass(item.titleLottoCatagory)"></span>
                      </div>
                    <div class="lotto-head" v-bind:class="getBackgroundColor(item.titleLottoCatagory)">
                      <h5>{{ item.titleLottoCatagory }}</h5>
                      <span class="badge badge-dark">ปิดรับ {{ getTime(item.lottoCloseTime) }}</span>
                    </div>
                    <div>
                      <div class="lotto-time text-success" v-if="item.isOpen !== '1'">
                        <i class="fas fa-ban"></i><span>ยังไม่รับแทง</span>
                      </div>
                      <div v-else class="lotto-time text-success">
                          <i class="fas fa-ban" v-if="item.isOpen !== '1'"></i>
                          <i class="far fa-check-circle"  v-else></i><span
                          class="countdown"
                          id="offset"
                          data-finaldate="1610871600000"
                          v-html="calTime(item.lottoCloseTime)"
                        >
                        </span>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </template>
          </div>
        </div>
      </div>
    </b-overlay>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import moment from 'moment'
import { dummyList } from '@/constants'
import {
  getClassIcons,
  getClassBackgroundIcons
} from '@/utils'
export default {
  data () {
    return {
      dummyList: dummyList,
      currentDate: '',
      show: true
    }
  },
  async created () {
    if (this.isGamePermission === 2) {

    }
    this.setLoading(true)
    setInterval(() => {
      this.getNow()
    }, 1000)
    await this.getYeekiCatalog()
    await this.getLottoInter()
    this.setLoading(false)
  },
  methods: {
    ...mapActions(['getLottery', 'setLoading', 'getLottoInter', 'getYeekiCatalog']),
    getIconClass (title) {
      if (title) {
        return getClassIcons(title)
      } else {
        return ''
      }
    },
    getBackgroundColor (title) {
      if (title) {
        const classes = getClassBackgroundIcons(title)
        return classes
      } else {
        return ''
      }
    },
    calTime (value) {
      const final = moment(value)
      const now = this.currentDate ? this.currentDate : ''
      const timeleft = final - now
      if (timeleft > 0) {
        const days = Math.floor(timeleft / (1000 * 60 * 60 * 24))
        const hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        let minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60))
        if (minutes < 10) {
          minutes = '0' + minutes
        }
        let seconds = Math.floor((timeleft % (1000 * 60)) / 1000)
        if (seconds < 10) {
          seconds = '0' + seconds
        }
        if (days > 1000) {
          return ''
        } else if (days > 0) {
          return `${days}<small>วัน</small> ${hours}<small>:</small>${minutes}<small>:</small>${seconds}`
        } else {
          return `${hours}<small>:</small>${minutes}<small>:</small>${seconds}`
        }
      } else {
        return '00<small>:</small>00<small>:</small>00'
      }
    },
    getIsClose (value) {
      const final = moment(value)
      const now = this.currentDate ? this.currentDate : ''
      const timeleft = final - now
      if (timeleft > 0) {
        return false
      } else {
        return true
      }
    },
    getTime (time) {
      const timer = moment(time)
      const min = timer.minutes().toString()
      const hour = timer.hours().toString()
      const pad = '00'
      const minCal = pad.substring(0, pad.length - min.length) + min
      const hourCal = pad.substring(0, pad.length - hour.length) + hour

      return `${hourCal}: ${minCal}`
    },
    getNow () {
      this.currentDate = moment()
    },
    getTimeStamp (time) {
      return moment(time)
    },
    isATimeOver (a, b) {
      return moment(a) < moment(b)
    }
  },
  computed: {
    ...mapGetters(['getLotteryList', 'getYeekiCatalogList']),
    ...mapState({
      isGamePermission: (state) => state.authModule.isGamePermission
    })
  }
}
</script>
